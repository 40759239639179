@import "../vars.scss";
.main {
    position: relative;
    color: #FFFFFF;
    background: #E7F2FA radial-gradient(50% 50% at 50% 50%, #47A8B6 0%, #40708B 100%);
    &__wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        overflow: hidden;
    }
    &__decor {
        position: absolute;
        pointer-events: none;
    }
    &__decor1 {
        left: 81px;
        top: 0;
    }
    &__decor2 {
        right: 0;
        top: 0;
    }
    &__decor3 {
        right: 0;
        bottom: 0;
    }
    &__slider {
        position: relative;
    }
    &__left {
        padding: 64px 0 94px;
        max-width: 350px;
        flex-shrink: 0;
    }
    &__desc {
        font-weight: 400;
        font-size: 20.3018px;
        line-height: 120%;
        margin-top: 8px;
    }
    &__btn {
        width: 136px;
        height: 46.1px;
        margin-top: 37px;
        border-radius: 37.2655px;
        font-weight: 600;
        font-size: 16.1356px;
    }
    &__img {
        max-width: 644px;
        display: flex;
        align-items: flex-end;
        img {
            width: 100%;
        }
    }
    .swiper {
        &-container {
            position: relative;
            overflow: hidden;
        }
        &-slide {
            display: flex;
            padding-right: 100px;
            overflow: hidden;
        }
        &-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            &-bullet {
                background: #102246;
                opacity: 0.36;
                &-active {
                    opacity: 1;
                }
            }
        }
        &-button {
            &-prev {
                left: -98px;
                border: none;
            }
            &-next {
                right: -48px;
                border: none;
            }
        }
    }
}

.banners {
    overflow: hidden;
    padding: 28px 0;
    .swiper {
        &-wrapper {
            align-items: stretch;
        }
        &-slide {
            height: auto;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.index-categories {
    overflow: hidden;
    padding: 28px 0;
    .swiper {
        &-wrapper {
            justify-content: space-between;
        }
        &-slide {
            width: 94px;
            &--big {
                width: 135px;
            }
        }
    }
    &__item {
        display: block;
    }
    &__img {
        width: 94px;
        height: 94px;
        border-radius: 50%;
        margin: 0 auto;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 121%;
        margin-top: 15px;
        text-align: center;
    }
}

.collection {
    padding: 28px 0;
    overflow: hidden;
    &__top {
        display: flex;
        align-items: center;
        img {
            margin-left: 13px;
        }
    }
    &__title {
        span {
            color: $red;
        }
    }
    &__btn {
        width: 134.33px;
        height: 41.79px;
        margin-left: 25px;
        border-radius: 31.9559px;
        font-weight: 500;
        font-size: 14px;
    }
    &__slider {
        margin-top: 20px;
        position: relative;
    }
    .swiper {
        &-container {
            overflow: hidden;
        }
        &-wrapper {
            align-items: stretch;
        }
        &-slide {
            height: auto;
        }
        &-button {
            &-next {
                right: -24px;
            }
            &-prev {
                left: -24px;
            }
            &-disabled {
                opacity: 0 !important;
            }
        }
    }
    .catalog-card {
        height: 100%;
    }
}

.news {
    padding: 40px 0 90px;
    &__top {
        display: flex;
        align-items: center;
    }
    &__btn {
        width: 134.33px;
        height: 41.79px;
        margin-left: 25px;
        border-radius: 31.9559px;
        font-weight: 500;
        font-size: 14px;
    }
    &__items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        margin-top: 30px;
    }
    &__item {
        display: flex;
        align-items: center;
        &-img {
            width: 170px;
            height: 170px;
            margin-right: 30px;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 110%;
        }
        &-date {
            font-size: 14px;
            line-height: 136%;
            color: #C4C4C4;
            margin-top: 8px;
        }
    }
}

.service-collection {
    padding: 40px 0 60px;
    background: #F4F7F8;
    overflow: hidden;
    &__slider {
        margin-top: 35px;
        position: relative;
    }
    .swiper {
        &-container {
            overflow: hidden;
        }
        &-wrapper {
            align-items: stretch;
        }
        &-slide {
            height: auto;
        }
        &-button {
            &-next {
                right: -24px;
            }
            &-prev {
                left: -24px;
            }
            &-disabled {
                opacity: 0 !important;
            }
        }
    }
    .service-card {
        height: 100%;
    }
    &__link {
        width: 456px;
        height: 56px;
        border-radius: 27.8336px;
        margin: 52px auto 0;
        font-weight: 500;
        font-size: 15px;
    }
}

.info {
    padding: 37px 0;
    &__title,
    h1,
    h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 110%;
        color: #242321;
        margin-bottom: 15px;
    }
    &__inner {
        column-count: 2;
    }
    &__left,
    &__right {
        width: calc(50% - 15px);
    }
}

.consult {
    padding: 114px 0;
    color: rgba(255, 255, 255, 0.98);
    &__inner {
        background: radial-gradient(70.1% 70.1% at 17.01% 88.56%, #01BBC1 0%, #168EE4 100%);
        border-radius: 25px;
        padding: 85px 40px 25px;
        position: relative;
    }
    &__mail {
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        position: absolute;
    }
    &__title {
        font-weight: 500;
        font-size: 31px;
        line-height: 111%;
        max-width: 610px;
        margin: 0 auto;
        text-align: center;
    }
    &__form {
        margin: 40px auto 0;
        width: 580px;
        max-width: 100%;
        display: flex;
        align-items: center;
        &-group {
            flex-grow: 1;
            margin-bottom: 0;
            input {
                background-color: #fff;
                padding: 0 20px;
                font-size: 14px;
            }
        }
    }
    &__btn {
        flex-shrink: 0;
        width: 162px;
        height: 45px;
        border-radius: 27.8336px;
        font-weight: 700;
        font-size: 14px;
        margin-left: 5px;
    }
    &__hint {
        font-size: 13px;
        margin-top: 55px;
        text-align: center;
    }
}

@media screen and (max-width: 1400px) {
    .main {
        .swiper {
            &-slide {
                padding-left: 50px;
            }
            &-button {
                &-prev {
                    left: 0px;
                }
                &-next {
                    right: 0px;
                }
            }
        }
    }
}

@media screen and (max-width: 1250px) {
    .collection,
    .service-collection {
        .swiper {
            &-button {
                &-next {
                    right: 0;
                }
                &-prev {
                    left: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .main {
        .swiper {
            &-slide {
                padding-right: 0;
            }
        }
        &__left {
            padding: 35px 0 40px;
        }
    }
    .info {
        &__inner {
            column-count: 1;
        }
        &__left,
        &__right {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1050px) {
    .collection {
        overflow: hidden;
        .swiper {
            &-container {
                overflow: unset;
            }
            &-slide {
                width: 230px;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .main {
        &__decor3 {
            display: none;
        }
        &__decor1 {
            width: 260px;
            left: -200px;
        }
        &__decor2 {
            width: 260px;
            right: -92px;
        }
        .swiper {
            &-slide {
                padding: 0 0 0 20px;
            }
            &-button {
                &-prev,
                &-next {
                    display: none;
                }
            }
            &-pagination {
                bottom: unset;
                top: 100%;
                margin-top: 10px;
                position: absolute;
            }
        }
        .container {
            padding: 0;
        }
        &__left {
            max-width: 310px;
        }
        &__img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 465px;
            img {
                transform: translateX(22%);
            }
        }
        &__desc {
            font-size: 18px;
            margin-top: 3px;
        }
        &__btn {
            margin-top: 16px;
        }
    }
    .collection,
    .service-collection {
        .swiper {
            &-button {
                &-next,
                &-prev {
                    display: none;
                }
            }
        }
    }
    .news {
        &__items {
            grid-template-columns: 1fr;
        }
    }
    .service-collection {
        .swiper {
            &-slide {
                width: 271px;
            }
        }
    }
    .consult {
        padding: 50px 0 90px;
        &__inner {
            padding: 80px 20px 32px;
        }
    }
}

@media screen and (max-width: 768px) {
    .main {
        &__img {
            width: 400px;
        }
    }
    .banners {
        padding: 38px 0 16px;
        .swiper {
            &-slide {
                width: 240px;
            }
        }
    }
    .consult {
        &__title {
            font-size: 22px;
            line-height: 26px;
        }
        &__form {
            margin-top: 28px;
            display: block;
            &-group {
                width: 100%;
            }
        }
        &__btn {
            margin: 15px auto 0;
        }
        &__hint {
            margin-top: 42px;
            line-height: 22px;
        }
        &__mail {
            width: 87px;
            transform: translateX(-50%);
            top: -21px;
        }
    }
}

@media screen and (max-width: 576px) {
    .main {
        &__decor {
            width: 160px;
        }
        &__decor1 {
            left: -130px;
        }
        &__decor3 {
            right: -160px;
        }
        &__left {
            max-width: 150px;
        }
        &__desc {
            font-size: 8.52051px;
        }
        &__btn {
            width: 57.08px;
            height: 19.35px;
            border-radius: 15.6401px;
            font-size: 6.772px;
        }
        &__img {
            width: 265px;
            max-width: 70%;
        }
        .swiper {
            &-pagination {
                margin-top: 8px;
                &-bullet {
                    width: 5px;
                    height: 5px;
                }
            }
        }
    }
    .index-categories {
        padding: 16px 0 24px;
        .swiper {
            &-slide {
                width: 78px;
                &--big {
                    width: 113px;
                }
            }
        }
        &__img {
            width: 78.19px;
            height: 78.19px;
        }
        &__title {
            font-size: 11.6457px;
            margin-top: 12px;
        }
    }
    .collection {
        padding: 24px 0;
        &__top {
            flex-wrap: wrap;
            margin-top: -10px;
            img {
                display: none;
            }
        }
        &__title {
            margin-top: 10px;
            padding-right: 5px;
            margin-right: auto;
        }
        &__btn {
            width: 106px;
            height: 28px;
            margin-left: 0;
            font-size: 13px;
            margin-top: 10px;
        }
        &__slider {
            margin-top: 17px;
        }
    }
    .news {
        padding: 25px 0 55px;
        &__btn {
            width: 106px;
            height: 28px;
            margin-left: 0;
            font-size: 13px;
            margin-left: auto;
        }
        &__item {
            &-img {
                width: 70px;
                height: 70px;
                margin-right: 20px;
            }
        }
    }
    .service-collection {
        padding: 35px 0 47px;
        &__slider {
            margin-top: 30px;
        }
        &__link {
            height: 52px;
            font-size: 14px;
            margin-top: 43px;
            max-width: 100%;
        }
    }
    .info {
        padding: 26px 0;
        &__title,
        h1,
        h2 {
            margin-bottom: 10px;
        }
    }
}