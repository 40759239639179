.catalog-item {
    padding: 28px 0 32px;
    &__inner {
        display: flex;
    }
    &__left {
        width: 670px;
        margin-right: 30px;
        flex-shrink: 0;
    }
    &__right {
        flex-grow: 1;
        padding-top: 7px;
    }
    &__gallery {
        &-big {
            width: 100%;
            height: 467px;
            border: 1px solid #E6ECF0;
            border-radius: 8px;
            &-item {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        &-small {
            margin: 20px auto 0;
            width: 230px;
            .swiper-slide {
                height: 70px;
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                &:before {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    border: 1px solid #D9D9D9;
                    border-radius: 5px;
                    left: 0;
                    top: 0;
                    transition: all .3s;
                }
                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: 50px;
                }
            }
            .swiper-slide-thumb-active {
                &:before {
                    border-color: #2D9AE9;
                }
            }
        }
        .swiper-button-next {
            right: 15px;
        }
        .swiper-button-prev {
            left: 15px;
        }
    }
    &__info {
        margin-top: -50px;
        max-width: 670px;
    }
    &__tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 27px;
    }
    &__tab {
        padding-bottom: 15px;
        font-weight: bold;
        font-size: 18px;
        line-height: 110%;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            z-index: 1;
            background: #2D9AE9;
            width: 100%;
            height: 2px;
            transition: all .3s;
            bottom: 0;
            left: 0;
            opacity: 0;
        }
        &:hover {
            cursor: pointer;
            &:before {
                opacity: 1;
            }
        }
        &--active {
            pointer-events: none;
            &:before {
                opacity: 1;
            }
        }
        span {
            color: $grey;
        }
    }
    &__content {
        display: none;
        &--active {
            display: block;
        }
        p {
            color: $black;
        }
    }
    &__feedback {
        &-item {
            padding-left: 53px;
            position: relative;
            &:not(:last-child) {
                padding-bottom: 10px;
                margin-bottom: 27px;
                border-bottom: 1px solid #F5F5F5;
            }
            &-img {
                position: absolute;
                background: #F0F8FF;
                width: 37px;
                height: 37px;
                border-radius: 50%;
                left: 0;
                top: -5px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            &-name {
                font-weight: bold;
                font-size: 16px;
                line-height: 136%;
            }
            &-date {
                font-size: 14px;
                line-height: 136%;
                color: rgba(36, 35, 33, 0.5);
                margin-top: 5px;
            }
            &-desc {
                font-size: 16px;
                line-height: 136%;
                margin-top: 5px;
            }
            &-images {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 7px;
                margin-top: 35px;
            }
            &-image {
                display: flex;
                align-items: center;
                justify-content: center;
                max-height: 214px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    &__add {
        height: 50px;
        width: 384px;
        max-width: 100%;
        margin: 54px auto 0;
        font-weight: 500;
        font-size: 16px;
        border-radius: 56px;
    }
    &__title {
        font-weight: 700;
        font-size: 26px;
        line-height: 110%;
    }
    &__prices {
        margin-top: 20px;
        display: flex;
        align-items: center;
    }
    &__price {
        font-weight: 500;
        font-size: 24px;
        line-height: 90%;
        &-new {
            font-size: 32px;
            line-height: 110%;
            color: $red;
            font-weight: 500;
        }
        &-old {
            font-weight: 500;
            margin-left: 8px;
            font-size: 18px;
            line-height: 90%;
            color: #242321;
            text-decoration: line-through;
            text-decoration-color: #F24941;
        }
    }
    &__calc {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        margin-top: 27px;
        button {
            background: #F5F5F5;
            width: 40px;
            height: 40px;
            border-radius: 6px;
        }
        input {
            margin: 0 15px;
            border: 1px solid #DCDCDC;
            border-radius: 6px;
            height: 40px;
            width: 50px;
            text-align: center;
        }
    }
    &__cart-btn {
        width: 380px;
        height: 50px;
        margin-top: 23px;
        max-width: 100%;
        border-radius: 56px;
        font-weight: 500;
        font-size: 16px;
    }
    &__colors {
        margin-top: 28px;
        &-title {
            font-weight: bold;
            font-size: 18px;
            line-height: 90%;
            margin-bottom: 15px;
        }
        &-wrap {
            display: flex;
        }
    }
    &__color {
        margin-right: 10px;
        position: relative;
        input {
            position: absolute;
            width: 1px;
            height: 1px;
            opacity: 0;
            &:checked+label {
                &::before {
                    opacity: 1;
                }
            }
        }
        label {
            display: block;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            cursor: pointer;
            transition: all .3s;
            margin-bottom: 0;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                z-index: 1;
                width: calc(100% + 3px);
                height: calc(100% + 3px);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                border-radius: 50%;
                box-shadow: 0px 0px 0px 2px #1DAFD1;
                transition: all .3s;
            }
        }
    }
    &__size {
        margin-top: 30px;
        &-title {
            font-weight: bold;
            font-size: 18px;
            line-height: 90%;
            margin-bottom: 15px;
        }
        &-select {
            max-width: 373px;
            height: 62px;
        }
    }
    &__properties {
        margin-top: 30px;
        &-title {
            font-weight: bold;
            font-size: 18px;
            line-height: 90%;
            margin-bottom: 15px;
        }
    }
    &__property {
        display: flex;
        &-left {
            width: 270px;
            flex-shrink: 0;
            margin-right: 30px;
            font-size: 16px;
            line-height: 156%;
            color: rgba(36, 35, 33, 0.5);
        }
        &-right {
            font-size: 16px;
            line-height: 156%;
        }
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}

.same-category {
    padding: 25px 0;
    overflow: hidden;
    &__slider {
        margin-top: 32px;
        display: flex;
    }
    .swiper-slide {
        max-width: 187px;
    }
    &__item {
        background: #EFF2F5;
        border-radius: 20px;
        height: 187px;
        padding-top: 16px;
        padding-left: 12px;
        display: flex;
        flex-direction: column;
        color: #000000;
        width: 100%;
        overflow: hidden;
        &-title {
            font-weight: 500;
            font-size: 18.321px;
            line-height: 22px;
            padding-right: 50px;
        }
        &-img {
            flex-grow: 1;
            img {
                max-width: 100%;
                max-height: 100%;
                display: block;
                margin: 0 auto;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .catalog-item {
        &__inner {
            display: block;
        }
        &__left {
            width: 100%;
            margin-right: 0;
        }
        &__right {
            width: 100%;
            margin-top: 30px;
        }
        &__info {
            margin-top: 30px;
        }
        &__gallery {
            &-big {
                width: 467px;
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }
}

@media screen and (max-width: 790px) {
    .catalog-item {
        &__title {
            br {
                display: none;
            }
        }
        &__tabs {
            width: 100%;
            overflow-x: auto;
            justify-content: flex-start;
            -ms-overflow-style: none;
            scrollbar-width: none;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        &__tab {
            font-size: 13px;
            flex-shrink: 0;
            white-space: nowrap;
            &:not(:last-child) {
                margin-right: 14px;
            }
        }
        &__property {
            &-left {
                width: calc(100% - 120px);
            }
        }
        &__prices {
            margin-top: 16px;
        }
        &__calc {
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 670px) {
    .catalog-item {
        &__gallery {
            &-big {
                height: 320px;
                padding: 25px 0;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .catalog-item {
        &__gallery {
            &-big {
                height: 220px;
                width: calc(100% + 20px);
                margin-left: -10px;
                max-width: unset;
            }
            &-small {
                margin-top: 13px;
                max-width: 180px;
                .swiper-slide {
                    height: 52px;
                    padding: 10px 0;
                    img {
                        width: unset;
                    }
                }
            }
            .swiper-button-next,
            .swiper-button-prev {
                width: 38px;
                height: 38px;
            }
            .swiper-button-next {
                right: 5px;
            }
            .swiper-button-prev {
                left: 5px;
            }
        }
        &__feedback {
            &-item {
                &-images {
                    width: calc(100% + 53px);
                    margin-left: -53px;
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}