@import "../vars";
.display1 {
    font-weight: 700;
    font-size: 39.882px;
    line-height: 117%;
    letter-spacing: -0.01em;
}

.display2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
}

p {
    font-size: 14px;
    line-height: 136%;
    color: #717171;
    margin-bottom: 20px;
}

li {
    font-size: 14px;
    line-height: 136%;
    color: #717171;
    position: relative;
    &:not(:last-child) {
        margin-bottom: 4px;
    }
}

ul {
    margin-bottom: 5px;
    li {
        margin-left: 10px;
        padding-left: 13px;
        &:before {
            content: '';
            position: absolute;
            z-index: 1;
            background: #717171;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            left: 0;
            top: 6px;
        }
    }
}

@media screen and (max-width: 992px) {
    .display1 {
        font-size: 27px;
    }
}

@media screen and (max-width: 576px) {
    .display1 {
        font-size: 16.7382px;
    }
    .display2 {
        font-size: 22px;
        line-height: 26px;
    }
}