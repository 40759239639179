@import 'vars';
@import 'mixins';
.modal-backdrop {
    &.show {
        opacity: 0.6;
    }
}

.modal {
    &-dialog {
        max-width: 586px;
        padding: 57px 49px;
        background-color: #fff;
        border-radius: 8px;
        pointer-events: unset;
        margin-left: auto;
        margin-right: auto;
    }
    &__close {
        position: absolute;
        left: 100%;
        top: 0;
        margin-left: 11px;
        background-color: transparent;
    }
    &__title {
        font-weight: 700;
        font-size: 26px;
        line-height: 110%;
        color: #242321;
    }
    &__form {
        margin-top: 45px;
        &-group {
            margin-bottom: 15px;
            input,
            textarea {
                border: 1px solid #DCDCDC;
                background-color: #fff;
                border-radius: 6px;
                padding: 0 15px;
            }
            input {
                height: 40px;
            }
            textarea {
                padding: 8px 15px;
            }
        }
    }
    &__subtitle {
        font-weight: 700;
        font-size: 18px;
        line-height: 110%;
        margin-top: 34px;
    }
    &__upload {
        display: flex;
        align-items: center;
        margin-top: 20px;
        &-input {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background: #F3F5F9;
            border-radius: 7.29px;
            width: 91px;
            height: 91px;
            flex-shrink: 0;
            margin-right: 34px;
            input {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }
        &-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 112%;
        }
        &-desc {
            margin-top: 10px;
            color: #737F8C;
            font-size: 14px;
            line-height: 112%;
        }
    }
    &__images {
        display: flex;
        flex-wrap: wrap;
        margin-top: 18px;
    }
    &__img {
        width: 57px;
        height: 57px;
        margin: 6px 8px 0 0;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &-remove {
            width: 12.39px;
            height: 12.39px;
            background: rgba(0, 0, 0, 0.62);
            border-radius: 50%;
            position: absolute;
            top: 3px;
            right: 4px;
        }
    }
    &__btn {
        width: 100%;
        height: 50px;
        border-radius: 56px;
        margin-top: 48px;
        font-weight: 500;
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .modal {
        &__close {
            left: unset;
            bottom: unset;
            top: 15px;
            right: 15px;
            margin: 0;
            path {
                fill: $black;
            }
        }
        &__form {
            margin-top: 30px;
        }
        &__subtitle {
            margin-top: 25px;
        }
        &__btn {
            margin-top: 30px;
        }
        &__upload {
            &-input {
                margin-right: 20px;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .modal {
        &-dialog {
            padding: 40px 20px;
        }
    }
}