@import '_header';
@import '_footer';
.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.container {
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 30px;
    @media screen and (max-width: 992px) {
        padding: 0 20px;
    }
    @media screen and (max-width: 576px) {
        padding: 0 10px;
    }
}