@import 'vars';
html {
    width: 100%;
}

body {
    font-family: $font1;
    font-weight: 400;
    color: $black;
    &.fixed-body {
        overflow-y: hidden;
    }
}

a {
    &:hover {
        color: $primary;
    }
}